<template>
    <div class="mv-login mt-5 px-5 py-3">
        <img src="../../assets/images/logo.png" class="logo mx-auto mb-4" />
        <b-form @submit="onSubmit" @reset="onReset">
            <b-form-group
                id="usernameInputGroup"
                label="Benutzename:"
                label-for="usernameInput"
            >
                <b-form-input
                    id="usernameInput"
                    type="text"
                    v-model="form.username"
                    required
                    placeholder="Benuternamen eingeben"
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="passwordInputGroup"
                label="Passwort:"
                label-for="passwordInput"
            >
                <b-form-input
                    id="passwordInput"
                    type="password"
                    v-model="form.password"
                    required
                    placeholder="Passwort eingeben"
                ></b-form-input>
            </b-form-group>
            <p v-if="error.occur" class="mt-3 text-danger text-center">
                {{ error.message }}
            </p>
            <Spinner v-if="busy" class="mt-3" small block />
            <b-button
                type="submit"
                variant="primary"
                block
                class="mt-3"
                :disabled="busy"
                >Anmelden</b-button
            >
            <b-button
                type="reset"
                variant="outline-secondary"
                block
                :disabled="busy"
                >Abbrechen</b-button
            >
            <b-button variant="link" to="/forget_password" :disabled="true"
                >Passwort vergessen?</b-button
            >
        </b-form>
    </div>
</template>

<script>
import Spinner from "@/components/Spinner/Spinner";

import "./Login.scss";

export default {
    name: "Login",
    components: {
        Spinner
    },
    data() {
        return {
            busy: false,
            form: {
                username: "",
                password: ""
            },
            error: {
                occur: false,
                message: ""
            }
        };
    },
    methods: {
        onSubmit: function(evt) {
            evt.preventDefault();

            this.error = {
                occur: false,
                message: ""
            };

            this.busy = true;
            this.$store
                .dispatch("auth/auth", this.form)
                .then(() => {
                    this.$router.push("/");
                })
                .catch(e => {
                    this.error = {
                        occur: true,
                        message:
                            "Der Benutzername und/oder das Passwort sind nicht korrekt"
                    };
                })
                .finally(() => {
                    this.busy = false;
                });
        },
        onReset: function(evt) {
            evt.preventDefault();
            /* Reset our form values */
            this.form.username = "";
            this.form.password = "";
        }
    }
};
</script>
