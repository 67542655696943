<template>
    <MainContainer>
        <div class="login">
            <b-row>
                <b-col
                    cols="12"
                    offset="0"
                    md="6"
                    offset-md="3"
                    lg="4"
                    offset-lg="4"
                >
                    <Login />
                </b-col>
            </b-row>
        </div>
    </MainContainer>
</template>

<script>
import MainContainer from "@/components/MainContainer/MainContainer";
import Login from "@/components/Login/Login";

export default {
    name: "login",
    components: {
        MainContainer,
        Login
    }
};
</script>
